import React from 'react';
import './App.css';

import { useAuth0 } from './react-auth0-wrapper';
import NavBar from './components/NavBar';
import Profile from './components/Profile';
import Main from './containers/Main';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import UserStatus from './components/UserStatus';
import { Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'lightblue',
  height: 60,
  padding: '0 20px',
  boxSizing: 'border-box',
});

function App() {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header>
        <UserStatus />
        {!isAuthenticated && (
          <Button onClick={() => loginWithRedirect({})}>Log in</Button>
        )}
        {isAuthenticated && <Button onClick={() => logout()}>Log out</Button>}
      </Header>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Main} />
            <PrivateRoute path="/profile" component={Profile} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
