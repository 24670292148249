import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { styled } from '@material-ui/styles';
import { flexbox } from '@material-ui/system';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const UserPhoto = styled(({ src, ...other }) => <div {...other} />)({
  backgroundImage: props => `url(${props.src})`,
  backgroundSize: 'contain',
  height: 50,
  width: 50,
  borderRadius: '50%',
  marginRight: 15,
});

const NameLabel = styled('div')({
  fontSize: '14px',
  display: 'block',
});

const EmailLabel = styled('div')({
  fontSize: '12px',
  display: 'block',
  textTransform: 'uppercase',
});

const UserStatus = () => {
  const { loading, user } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>You are not logged in!</div>;
  }

  return (
    <Container>
      <UserPhoto src={user.picture} />
      <div>
        <NameLabel>{user.name}</NameLabel>
        <EmailLabel>{user.email}</EmailLabel>
      </div>
    </Container>
  );
};

export default UserStatus;
