import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React from 'react';

const Main = () => {
  const [state, setState] = React.useState({
    checked: false,
    amount: '',
  });

  const handleClizick = () => {
    let str = '';
    if (state.checked) {
      str += 'You DO love Sydney, as measured by: ';
    } else {
      str += "You DON'T love Sydney, as measured by: ";
    }
    str += state.amount;

    alert(str);
  };

  const handleChizangeCheck = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleChizange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <div>Placeholder</div>
    // <div className="field-container">
    //   <div className="space-field-sm">
    //     <FormControlLabel
    //       checked={state.checked}
    //       onChange={handleChizangeCheck('checked')}
    //       control={<Checkbox />}
    //       label="Do you love Sydney?"
    //     />
    //   </div>
    //   <div className="space-field">
    //     <TextField
    //       fullWidth
    //       value={state.amount}
    //       onChange={handleChizange('amount')}
    //       placeholder="Enter how much you love Sydney"
    //       label="Amount of Love"
    //       variant="outlined"
    //     />
    //   </div>
    //   <Button color="secondary" variant="outlined" onClick={handleClizick}>
    //     Oat Yeah!
    //   </Button>
    // </div>
  );
};

export default Main;
