import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { styled } from '@material-ui/styles';

const Profile = () => {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <code>{JSON.stringify(user, null, 2)}</code>
    </>
  );
};

export default Profile;
